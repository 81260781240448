"use client";

import Text from "@/lib/components/Text";
import { MenuEntry } from "@/lib/constants/menuEntries";
import { Urls, getUrl } from "@/lib/constants/urls";
import { useRegisterNextParams } from "@/lib/hooks/useRegisterNextParams";
import { useTranslation } from "@/lib/i18n/client";
import { LanguageParams } from "@/lib/types/PageProps";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

type Props = {
    entry: MenuEntry | MenuEntry[];
    transparent: boolean;
} & LanguageParams;

const MenuItem = ({ entry, transparent, lang }: Props) => {
    const { t } = useTranslation(lang, "menu");
    const pathname = usePathname();
    const registerParams = useRegisterNextParams();

    const getLink = (entry: MenuEntry) => {
        const { key: urlTextKey, url, onClick, type } = entry;
        let fullUrl = getUrl(url, lang);
        const registerUrl = getUrl(Urls.register.index, lang);
        if (urlTextKey === "register" && registerUrl !== pathname) {
            fullUrl = getUrl(url, lang, null, registerParams);
        }

        const commonProps = {
            className: `block py-8 border-y-4 cursor-pointer border-transparent leading-8 ${
                transparent ? "hover:border-t-white" : "hover:border-t-primary-500 hover:text-primary-500"
            }`,
        };

        if (type === "modal") {
            // Render a clickable element without navigation
            return (
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        onClick?.();
                    }}
                    {...commonProps}
                >
                    <Text className="whitespace-nowrap">{t(urlTextKey)}</Text>
                </a>
            );
        }

        return (
            <Link href={fullUrl} {...commonProps}>
                <Text className="whitespace-nowrap">{t(urlTextKey)}</Text>
            </Link>
        );
    };

    if (Array.isArray(entry)) {
        return (
            <div className="flex gap-6">
                {entry.map((e, i) => (
                    <React.Fragment key={e.key}>
                        {i !== 0 && (
                            <Text className="block py-8 border-y-4 border-transparent select-none leading-8">|</Text>
                        )}
                        {getLink(e)}
                    </React.Fragment>
                ))}
            </div>
        );
    } else {
        return getLink(entry);
    }
};

export default MenuItem;
