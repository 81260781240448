import { LoadScriptProps, useLoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";


const libraries: LoadScriptProps["libraries"] = ["places"];

export function useGoogleMap(lang: string) {
    const [isLoadedState, setIsLoadedState] = useState(false);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
        libraries: libraries,
        language: lang,
        region: lang,
    });

    useEffect(() => {
        setIsLoadedState(isLoaded);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded]);

    return isLoadedState;
}
