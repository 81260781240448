"use client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getUrl } from "@/lib/constants/urls";
import { LanguageParams } from "@/lib/types/PageProps";

import Text from "../Text";
import { usePathname } from "next/navigation";
import { MenuEntry } from "@/lib/constants/menuEntries";
import { useTranslation } from "@/lib/i18n/client";
import Link from "next/link";
import { useLinkIsActive } from "@/lib/hooks/use-link-is-active";

type SubMenuItemProp = {
    itemData: MenuEntry;
} & LanguageParams;

const SubMenuItem = ({ itemData, lang }: SubMenuItemProp) => {
    const pathName = usePathname();
    const [isActive] = useLinkIsActive(itemData.url, lang, pathName);
    const { t } = useTranslation(lang, "desktop-menu");

    return (
        <Link
            href={getUrl(itemData.url, lang)}
            className={`w-full block py-4 pl-12   hover:text-white hover:bg-cyan-300 ${
                isActive ? " text-white bg-cyan-300 " : " bg-cyan-400 text-black "
            }} select-none no-highlight`}
        >
            {itemData.menuIcon && (
                <div className="inline-block h-5 w-8 text-center">
                    <FontAwesomeIcon icon={itemData.menuIcon} className="m-auto" />
                </div>
            )}
            <Text className={`whitespace-nowrap font-medium ${itemData.menuIcon ? "" : "pl-8"} `}>
                {t(itemData.key)}
            </Text>
        </Link>
    );
};

export default SubMenuItem;
