import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { UrlTranslation, Urls } from "./urls";
import { faUser, faHouse, faEnvelope, faDoorOpen, faFile } from "@fortawesome/pro-solid-svg-icons";

export type MenuEntry = {
    type?: "url" | "modal";
    key: string;
    menuIcon?: IconDefinition;
    hasSubMenu?: boolean;
    url: string | UrlTranslation;
    onClickHandler?: (e: Event) => void;
    onClick?: () => void;
    subMenuItems?: (MenuEntry | MenuEntry[])[];
};

const ProfileSubMenu: (MenuEntry | MenuEntry[])[] = [
    { key: "Security", url: Urls.security.index, type: "url" },
    { key: "Payment", url: Urls.payment.index, type: "url" },
    { key: "Billing", url: Urls.billing.index, type: "url" },
];

export const GuestPanelMenuEntries: (MenuEntry | MenuEntry[])[] = [
    // { key: "blog", url: Urls.blog.index },
    // { key: "Dashboard", menuIcon:faTv, url: Urls.faq.guest, type: "url" }, will be new feature
    {
        key: "Profile",
        menuIcon: faUser,
        url: Urls.userProfile.index,
        type: "url",
        hasSubMenu: true,
        subMenuItems: ProfileSubMenu,
    },
    { key: "Reservations", menuIcon: faHouse, url: Urls.reservations.index, type: "url" },
    { key: "Invoices", menuIcon: faFile, url: Urls.invoices.index, type: "url" },
    { key: "Messages", menuIcon: faEnvelope, url: Urls.messages.index, type: "url" },
    // { key: "Invoices", menuIcon:faFile, url: Urls.invoices.index, type: "url" },
    // { key: "Favorites",menuIcon: faHeart, url: Urls.contactUs.index, type: "url" },
    // { key: "Reviews", menuIcon: faStar, url: Urls.contactUs.index, type: "url" },
    { key: "Log out", menuIcon: faDoorOpen, url: "logout", type: "url" },
];

export const MenuEntries: (MenuEntry | MenuEntry[])[] = [
    // { key: "blog", url: Urls.blog.index },
    { key: "faq", url: Urls.faq.guest, type: "url" },
    { key: "contact-us", url: Urls.contactUs.index, type: "url" },
];

// if this entry is part of user menu or regular menu (on desktop they're combined, on mobile split into two dropdowns)
export const AnonymousUserMenuEntries: MenuEntry[] = [
    { key: "login", url: "", type: "modal" },
    { key: "register", url: Urls.register.index, type: "url" },
];

export const LoggedInUserMenuEntries: (MenuEntry | MenuEntry[])[] = [
    [
        { key: "profile", url: "", type: "modal" },
        { key: "reservations", url: "", type: "url" },
        { key: "messages", url: "", type: "url" },
    ],
];

export const MENU_SHOW_INPUT_THRESHOLD = 400;
