import { Urls, getUrl } from "../constants/urls";
import { UserAtom, initialUserInfo } from "../jotai/auth/authStore";
import { API } from "../services/authService";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

/**
 * Logout
 *
 * Clear out Jotai LocalStorage
 * Make API call to logout endpoint
 *
 */

export const userLogOut = async (
    setAuth: (args: UserAtom) => void,
    router: AppRouterInstance,
    lang: string,
    jotaiAuthObject: UserAtom,
) => {
    try {
        await API.Logout();
        setAuth({
            ...jotaiAuthObject,
            isLoggedIn: false,
            userInfo: initialUserInfo,
        });
    } catch (error) {
        console.log("Something went wrong with the logout process!", error);
    }
    router.push(getUrl(Urls.home.index, lang));
};
