import { MAX_NUMBER_OF_GUESTS, MIN_NUMBER_OF_GUESTS } from "@/lib/constants/guestPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus } from "@fortawesome/pro-light-svg-icons/faSquareMinus";
import { faSquarePlus } from "@fortawesome/pro-light-svg-icons/faSquarePlus";
import Text from "../Text";

type Props = {
    numGuest: number;
    setGuests: (guests: number) => void;
    className?: string;
    maxGuests?: number;
};

const GuestPicker = ({ numGuest, className, maxGuests = MAX_NUMBER_OF_GUESTS, setGuests }: Props) => {
    return (
        <div className={`flex justify-between pt-3.5 pb-3.5 px-3 text-xl w-full ${className}`}>
            <div className="col-span-2">
                <button
                    onClick={() => {
                        if (numGuest > MIN_NUMBER_OF_GUESTS) setGuests(numGuest - 1);
                    }}
                    disabled={numGuest === MIN_NUMBER_OF_GUESTS}
                    className={`${numGuest === MIN_NUMBER_OF_GUESTS ? "text-gray-200" : "black"}`}
                >
                    <FontAwesomeIcon icon={faSquareMinus} className="fa-xl" />
                </button>
            </div>
            <Text className="col-span-2">{numGuest}</Text>
            <div className="col-span-2">
                <button
                    onClick={() => {
                        if (numGuest < maxGuests) setGuests(numGuest + 1);
                    }}
                    disabled={numGuest === 6}
                    className="text-black disabled:text-gray-200"
                >
                    <FontAwesomeIcon icon={faSquarePlus} className="fa-xl" />
                </button>
            </div>
        </div>
    );
};

export default GuestPicker;
