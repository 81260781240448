"use client";
import { DetailedHTMLProps, forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";

type ButtonVariant = "orange" | "transparent" | "black" | "green" | "custom" | "gray";
type ButtonSize = "md" | "lg" | "custom";
type FontSize = "xxs" | "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl";
type IconDirection = "left" | "right";
type IconProp = React.ComponentProps<typeof FontAwesomeIcon>["icon"];

type Props = {
    href: string;
    variant?: ButtonVariant;
    size?: ButtonSize;
    fontSize?: FontSize;
    icon?: IconProp;
    direction?: IconDirection;
} & DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

const LinkButton = forwardRef<HTMLAnchorElement, Props>(
    (
        { href, fontSize = "base", size = "lg", variant = "orange", icon, direction = "left", className, ...rest },
        ref,
    ) => {
        let variantCss = "";
        switch (variant) {
            case "green":
                variantCss = "text-white bg-green-500 hover:bg-green-700";
                break;
            case "black":
                variantCss =
                    "text-white bg-black hover:text-white hover:border-2 hover:border-solid hover:border-black";
                break;
            case "orange":
                variantCss = "text-white bg-primary-500 hover:bg-primary-400";
                break;
            case "transparent":
                variantCss = "bg-transparent text-black hover:text-primary-500 border-2 border-solid border-black";
                break;
            case "gray":
                variantCss =
                    "bg-white border-2 border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white font-bold";
                break;
            default:
        }

        let sizeCss = "";
        switch (size) {
            case "md":
                sizeCss = "h-11";
                break;
            case "lg":
                sizeCss = "h-14";
                break;
            default:
        }

        const fontSizeCss = `text-${fontSize}`;

        const getFlexDirection = (direction: IconDirection) => {
            return direction === "left" ? "flex-row" : "flex-row-reverse";
        };

        const renderIcon = () => icon && <FontAwesomeIcon icon={icon} />;
        const flexDirection = getFlexDirection(direction);

        return (
            <Link href={href} {...rest} ref={ref}>
                <div className={`${sizeCss} ${fontSizeCss} rounded-md ${variantCss} ${className}`}>
                    <div className={`flex gap-2 ${flexDirection} h-full items-center justify-center`}>
                        {renderIcon()}
                        {rest.children}
                    </div>
                </div>
            </Link>
        );
    },
);

LinkButton.displayName = "LinkButton";
export default LinkButton;
