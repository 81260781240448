import React from "react";
import CloudImage from "./CloudImage";
import { cloudinaryCoyotivLoader } from "../setup/cloudinaryLoader";
import Image from "next/image";

type Props = {
    image: string;
    size?: number;
    className: string;
    alt?: string;
};

const UserProfilePicture = ({ image, size = 32, className, alt = "user profile picture" }: Props) => {
    if (image.startsWith("http")) {
        return (
            <Image
                loader={({ src }) => {
                    return src;
                }}
                alt={alt}
                height={size}
                width={size}
                className={className}
                src={image}
            />
        );
    }

    return (
        <CloudImage
            src={image}
            alt={alt}
            height={size}
            width={size}
            className={className}
            loader={cloudinaryCoyotivLoader}
        />
    );
};

export default UserProfilePicture;
