"use client";

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UrlTranslation, getUrl } from "../constants/urls";

/**
 * Custom hook that watches the URL of the browser
 * and sets active proprety of the links
 *
 * @param url
 * @param lang
 * @param pathName
 * @returns [boolean, Dispatch<SetStateAction<boolean>>]
 */
export function useLinkIsActive(url: string | UrlTranslation, lang: string, pathName: string): [boolean, Dispatch<SetStateAction<boolean>>] {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const menuItemUrl = getUrl(url, lang);
        if (menuItemUrl === pathName) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [pathName, lang, url]);

    return [isActive, setIsActive];
}
