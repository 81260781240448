export type SearchLocation = {
    name: string;
    placeId?: string;
}

export function buildLocations(predictions: google.maps.places.AutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus | undefined) {
    switch (status) {
        case google.maps.places.PlacesServiceStatus.OK:
            if (predictions && predictions.length > 0) {
                return predictions.map((prediction) => {
                    return {
                        name: prediction.description,
                        placeId: prediction.place_id,
                    } as SearchLocation;
                });
            } else {
                return [];
            }
        case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
            return [];
        default:
            console.warn("Querying Google Maps failed with status", status);
            return [];
    }
}
