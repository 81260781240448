type Props = {
    flagIcon: string;
    name: string;
    dark: boolean;
};

const LanguageSelectorIcon = ({ flagIcon, name, dark }: Props) => {
    return (
        <div title={name} className={`inline-block h-4 w-4 rounded-full ring-1 ${dark ? "ring-black" : "ring-white"} fi-${flagIcon} bg-cover bg-no-repeat bg-top`} />
    );
};

export default LanguageSelectorIcon as unknown as (props: Props) => JSX.Element;
