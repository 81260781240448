import { atomWithStorage } from "jotai/utils";
import {
    CheckoutBillingBusinessFormData,
    CheckoutBillingPrivateFormData,
    CheckoutGuestFormData,
} from "@/lib/services/checkoutService";
import { CheckoutFormsAtom } from "./types";

export const checkoutGuestForm: CheckoutGuestFormData = {
    firstName: "",
    lastName: "",
    email: "",
    guestPhone: "",
    country: "",
    additionalGuests: [],
};

export const checkoutBillingPrivateForm: CheckoutBillingPrivateFormData = {
    street: "",
    number: "",
    additionalAddressLine: "",
    zipCode: "",
    city: "",
    country: "",
};

export const checkoutBillingBusinessForm: CheckoutBillingBusinessFormData = {
    street: "",
    number: "",
    additionalAddressLine: "",
    zipCode: "",
    city: "",
    country: "",
    companyName: "",
    vatId: "",
};

export const checkoutFormsAtom = atomWithStorage<CheckoutFormsAtom>("checkoutForms", {
    checkoutGuestForm: checkoutGuestForm,
    checkoutPrivateBillingForm: checkoutBillingPrivateForm,
    checkoutBusinessBillingForm: checkoutBillingBusinessForm,
});
