"use client";
import { LanguageParams } from "@/lib/types/PageProps";
import { MenuEntry } from "@/lib/constants/menuEntries";
import { Transition } from "@headlessui/react";
import SubMenuItem from "./SubeMenuItem";

type SubMenuProp = {
    showSubMenu: boolean;
    subMenuItems?: (MenuEntry[] | MenuEntry)[] | MenuEntry;
} & LanguageParams;

const SubMenu = ({ showSubMenu = false, subMenuItems = [], lang }: SubMenuProp) => {
    return (
        <>
            <Transition
                show={showSubMenu}
                enter="transition-transform ease-in-out duration-200 transform"
                enterFrom="-translate-y-2"
                enterTo="translate-y-0"
                leave="transition-transform ease-in-out duration-90 transform"
                leaveFrom="translate-y-0"
                leaveTo="-translate-y-2"
            >
                {(subMenuItems as []).map((el: MenuEntry) => {
                    return <SubMenuItem key={el.key} itemData={el} lang={lang} />;
                })}
            </Transition>
        </>
    );
};

export default SubMenu;
