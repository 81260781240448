import { useLanguage } from "@/lib/hooks/useLanguage";
import { Popover } from "@headlessui/react";
import { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes, forwardRef, useEffect, useState } from "react";
import { faCaretDown, faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import Text from "../Text";
import { useTranslation } from "@/lib/i18n/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebounce, useClickAway } from "@uidotdev/usehooks";
import { useGoogleMapsAutocomplete } from "@/lib/hooks/useGoogleMapsAutocomplete";
import { SearchLocation, buildLocations } from "@/lib/utils/googleMapsUtils";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import Input from "../Input";

type LocationInputProps = {
    onLocationSelect?: (location: SearchLocation) => void;
    onLocationReset?: () => void;
    setErrorMessage?: (value: string | null) => void;
    initialValue?: SearchLocation;
    errorMessage?: null | string;
} & Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "ref">;

type AutocompleteRowProps = {
    location: SearchLocation;
    onSelect: (location: SearchLocation) => void;
};
const AutocompleteRow = ({ location, onSelect }: AutocompleteRowProps) => {
    return (
        <button
            key={location.name}
            className="hover:bg-cyan-500 hover:text-white w-full text-left py-1.5 px-5"
            onClick={() => onSelect(location)}
        >
            <FontAwesomeIcon icon={faLocationDot} className="pr-2" />
            {location.name}
        </button>
    );
};

const LocationInput = forwardRef<HTMLInputElement, LocationInputProps>(
    (
        {
            className,
            onLocationSelect,
            onLocationReset,
            initialValue = undefined,
            errorMessage = null,
            setErrorMessage = () => {},
            ...rest
        },
        ref,
    ) => {
        const lang = useLanguage();
        const { t } = useTranslation(lang, "search");
        const { t: tCities } = useTranslation(lang, "most-searched-cities");
        const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
        const [value, setValue] = useState<string | undefined>(initialValue?.name || "");
        const autoCompleteService = useGoogleMapsAutocomplete();

        const mostSearchedList: SearchLocation[] = [
            { name: tCities("berlin") },
            { name: tCities("munich") },
            { name: tCities("frankfurt") },
            { name: tCities("hamburg") },
            { name: tCities("dusseldorf") },
        ];
        const [items, setItems] = useState<SearchLocation[]>(mostSearchedList);

        const debouncedValue = useDebounce(value, 300);

        const closeDropdown = () => {
            setIsDropdownOpen(false);
        };
        const refDropdown = useClickAway(closeDropdown);

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value);
        };

        const onFetchedPredictions = (
            predictions: google.maps.places.AutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus | undefined,
        ) => {
            setItems(buildLocations(predictions, status));
        };

        useEffect(() => {
            if (debouncedValue) {
                if (autoCompleteService) {
                    autoCompleteService.getPlacePredictions(
                        {
                            input: debouncedValue,
                            types: ["(cities)"],
                        },
                        onFetchedPredictions,
                    );
                } else {
                    console.warn("Autocomplete not yet initialized!");
                }
            } else {
                resetDropdown();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [debouncedValue, autoCompleteService]);

        useEffect(() => {
            if (initialValue?.name) {
                setValue(initialValue.name);
            }
        }, [initialValue]);

        const resetDropdown = () => {
            setItems(mostSearchedList);
        };

        const onLocationClick = (location: SearchLocation) => {
            setErrorMessage(null);
            setValue(location.name);
            setIsDropdownOpen(false);
            if (onLocationSelect) {
                onLocationSelect(location);
            }
        };

        const resetValue = () => {
            setValue("");
            if (onLocationReset) {
                onLocationReset();
            }
        };

        return (
            <div className={className} ref={refDropdown as React.Ref<HTMLDivElement>}>
                <Input
                    ref={ref}
                    {...rest}
                    onFocus={() => {
                        setIsDropdownOpen(true);
                    }}
                    // Not setting errorMessages becuse the Input looks bad in this case-scenario
                    status={errorMessage ? "error" : "none"}
                    className={isDropdownOpen ? "rounded-t-md" : ""}
                    icon={!value ? faCaretDown : faXmark}
                    onIconClick={resetValue}
                    iconDirection="right"
                    value={value}
                    required
                    noRequiredAsterisk={true}
                    onChange={handleChange}
                    useDefaultBorderStyles={!isDropdownOpen}
                />

                {isDropdownOpen && (
                    <Popover className={`relative`}>
                        <Popover.Panel
                            static
                            className="absolute z-10 text-black bg-white rounded-b-md w-full border border-t-0 border-cyan-500"
                        >
                            <div className="flex flex-col text-left py-2">
                                {!value && (
                                    <Text className="text-base font-bold px-5 pb-3 pt-2">
                                        {t("most-searched-cities")}
                                    </Text>
                                )}
                                {items.map((item) => {
                                    return (
                                        <AutocompleteRow key={item.name} location={item} onSelect={onLocationClick} />
                                    );
                                })}
                            </div>
                        </Popover.Panel>
                    </Popover>
                )}
            </div>
        );
    },
);

LocationInput.displayName = "LocationInput";

export default LocationInput;
