"use client";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { UrlTranslation, getUrl } from "@/lib/constants/urls";
import { LanguageParams } from "@/lib/types/PageProps";

import Text from "../Text";
import { usePathname } from "next/navigation";
import { useLinkIsActive } from "@/lib/hooks/use-link-is-active";
import { MenuEntry } from "@/lib/constants/menuEntries";
import { useState } from "react";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "@/lib/i18n/client";
import Link from "next/link";
import SubMenu from "./SubMenu";

type GenericMenuItemProps = {
    isMobile?: boolean;
    url: string | UrlTranslation;
    text: string;
    itemIcon?: IconDefinition;
    hasSubMenu?: boolean;
    subMenuItems?: (MenuEntry[] | MenuEntry)[] | MenuEntry;
    onClickHandler?: (e: Event) => void | null;
} & LanguageParams;

const GenericMenuItem = ({
    isMobile = false,
    url,
    text,
    itemIcon,
    lang,
    hasSubMenu = false,
    subMenuItems = [],
    onClickHandler,
}: GenericMenuItemProps) => {
    const pathName = usePathname();
    const { t } = useTranslation(lang, "desktop-menu");
    const [isActive] = useLinkIsActive(url, lang, pathName);
    const [showSubMenu, setShowSubMenu] = useState(false);

    const processActiveStatus = (isActive = false): string => {
        if (isActive || showSubMenu) {
            return " bg-cyan-500 text-white select-none ";
        }
        return "";
    };

    return (
        <>
            <Link
                href={getUrl(url, lang)}
                onClick={(e) => {
                    if (hasSubMenu) setShowSubMenu(!showSubMenu);
                    if (onClickHandler) {
                        e.preventDefault();
                        onClickHandler(e as unknown as Event);
                    }
                }}
                className={`w-full block hover:bg-cyan-500 hover:text-white select-none no-highlight ${
                    isMobile ? "p-4 pl-5" : "py-4 pl-12"
                }
                ${processActiveStatus(isActive)}`}
            >
                {itemIcon && (
                    <div className="inline-block h-20px w-8 text-center">
                        <FontAwesomeIcon icon={itemIcon} className="m-auto" />
                    </div>
                )}
                <Text className="whitespace-nowrap font-medium">{t(text)}</Text>
                {hasSubMenu && (
                    <div className="inline-block h-5 w-8 text-right">
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`m-auto transition-transform ease-in-out duration-200 ${
                                showSubMenu ? " rotate-180 " : "rotate-80"
                            } `}
                        />
                    </div>
                )}
            </Link>
            {hasSubMenu && <SubMenu subMenuItems={subMenuItems} lang={lang} showSubMenu={showSubMenu} />}
        </>
    );
};

export default GenericMenuItem;
