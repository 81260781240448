import { useEffect, useState } from "react";
import { useLanguage } from "./useLanguage";
import { useGoogleMap } from "./useGoogleMap";


export function useGoogleMapsAutocomplete() {
    const lang = useLanguage();
    const isLoaded = useGoogleMap(lang);
    const [autoCompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | undefined>(undefined);

    useEffect(() => {
        if (isLoaded) {
            setAutocompleteService(new google.maps.places.AutocompleteService());
        }
    }, [isLoaded]);

    return autoCompleteService;
}
