"use client";

import Text from "@/lib/components/Text";
import { useTranslation } from "@/lib/i18n/client";
import { LanguageSettings } from "@/lib/i18n/settings";
import { LanguageParams } from "@/lib/types/PageProps";
import Link from "next/link";
import LanguageSelectorIcon from "./LanguageSelectorIcon";
import { ReadonlyURLSearchParams } from "next/navigation";
import { authAtom } from "@/lib/jotai/auth/authStore";
import { useAtomValue } from "jotai";
import { checkoutFormsAtom } from "@/lib/jotai/checkout/checkoutStore";
import { getOnLanguageLink } from "./onLanguageLink";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";

type Props = {
    targetLanguage: string;
    entryData: LanguageSettings;
    currentPath: string;
    searchParams: ReadonlyURLSearchParams;
    languageMappings: Record<string, string[][]>;
    current?: boolean;
} & LanguageParams;

const LanguageSelectorItem = ({
    lang: currentLanguage,
    targetLanguage,
    entryData,
    currentPath,
    searchParams,
    languageMappings,
    current = false,
}: Props) => {
    const { t } = useTranslation(currentLanguage, "menu");
    const auth = useAtomValue(authAtom);
    const checkoutForms = useAtomValue(checkoutFormsAtom);
    const userIdForGTM = auth.isLoggedIn ? auth.userInfo._id : undefined;

    return (
        <li className={`flex ${current && "border-t border-b border-gray-300"} hover:bg-gray-200 cursor-pointer`}>
            <Link
                className={`flex flex-grow items-center ${current ? "p-6" : "py-4 px-6"}`}
                onClick={(event) => {
                    GoogleTagManagerEvents.clickOnLanguageSwitcher(currentLanguage, targetLanguage, userIdForGTM);
                    return getOnLanguageLink(
                        currentPath,
                        searchParams,
                        languageMappings,
                        targetLanguage,
                        currentLanguage,
                        auth,
                        checkoutForms,
                    )(event);
                }}
                href="#"
            >
                <LanguageSelectorIcon flagIcon={entryData.flagIcon} name={entryData.localName} dark={true} />
                <div className="flex-grow">
                    <Text className="ml-2">{entryData.localName}</Text>
                    {current && <Text className="text-gray-500"> ({t("selected")})</Text>}
                </div>
                {/* <div><Text>{entryData.currency}{entryData.currencySign && ` ${entryData.currencySign}`}</Text></div> */}
            </Link>
        </li>
    );
};

export default LanguageSelectorItem as unknown as (props: Props) => JSX.Element;
