import { Popover } from "@headlessui/react";
import { DetailedHTMLProps, InputHTMLAttributes, forwardRef, useMemo, useEffect, useState, RefObject } from "react";
import { useClickAway } from "@uidotdev/usehooks";
import GuestPicker from "./GuestPicker";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "@/lib/i18n/client";
import Input from "../Input";
import { useTailwindBreakpoint } from "@/lib/hooks/useTailwindBreakpoint";
import { breakpointIsGreaterThan } from "@/lib/utils/tailwind";
import { SCREEN_SIZE } from "@/lib/constants/tailwind";
import { usePathname } from "next/navigation";
import { LanguageParams } from "@/lib/types/PageProps";

type GuestPickerInputProps = {
    maxGuests?: number;
    guestCount: number;
    className?: string;
    setGuestCount: (count: number) => void;
} & Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "ref"> &
    LanguageParams;

const GuestPickerInput = forwardRef<HTMLInputElement, GuestPickerInputProps>(
    ({ className, maxGuests, guestCount = 1, setGuestCount = () => {}, lang, ...rest }, ref) => {
        const [isGuestPickerOpen, setIsGuestPickerOpen] = useState<boolean>(false);
        const { t } = useTranslation(lang, "search");
        const pathName = usePathname();
        const breakpoint = useTailwindBreakpoint()[1];
        const TABLET_BREAKPOINT: SCREEN_SIZE = "md";
        const isMobile = breakpointIsGreaterThan(TABLET_BREAKPOINT, breakpoint);

        const closeGuestPicker = () => {
            if (isMobile) return;
            setIsGuestPickerOpen(false);
        };
        const refGuestPicker = useClickAway(closeGuestPicker);

        useEffect(() => {
            // Due to the fact that we use query params we need to close on route change
            setIsGuestPickerOpen(false);
            if (ref) (ref as unknown as RefObject<HTMLInputElement>).current?.blur();
        }, [pathName, ref]);

        useMemo(() => {
            if (isMobile) {
                setIsGuestPickerOpen(true);
            }
        }, [isMobile]);

        return (
            <div className={className}>
                <Input
                    ref={ref}
                    {...rest}
                    type="text"
                    placeholder="Guests"
                    onFocus={() => {
                        setIsGuestPickerOpen(true);
                    }}
                    icon={faUser}
                    iconDirection="left"
                    readOnly
                    value={`${guestCount} ${t("guestpicker.guests", { count: guestCount })}`}
                />

                {isGuestPickerOpen && (
                    <Popover ref={refGuestPicker as React.Ref<HTMLElement>} className={`relative top-1`}>
                        <Popover.Panel
                            static
                            className={`absolute sm:z-[9] z-0 text-black bg-white rounded-md w-full border border-gray-300 lg:border-cyan-500`}
                        >
                            <div className="flex flex-col">
                                <GuestPicker maxGuests={maxGuests} numGuest={guestCount} setGuests={setGuestCount} />
                            </div>
                        </Popover.Panel>
                    </Popover>
                )}
            </div>
        );
    },
);

GuestPickerInput.displayName = "GuestPickerInput";
export default GuestPickerInput;
