import { useAtom, SetStateAction } from "jotai";
import { UserAtom, authAtom } from "../jotai/auth/authStore";
import { checkoutFormsAtom } from "../jotai/checkout/checkoutStore";
import { API } from "../services/authService";
import { useEffect } from "react";
import { CheckoutFormsAtom } from "../jotai/checkout/types";
import { UserInfo } from "../jotai/auth/types";
import { useRouter } from "next/navigation";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SetAtom<Args extends any[], Result> = (...args: Args) => Result;

export async function getUserInfo(
    auth: UserAtom,
    setAuth: SetAtom<[SetStateAction<UserAtom>], void>,
    setCheckoutForms: SetAtom<[SetStateAction<CheckoutFormsAtom>], void>,
    router: AppRouterInstance,
    errorCallback?: () => void,
) {
    try {
        let userInfo: UserInfo | null = null;
        const hashData = window.location.hash
            .replace("#", "")
            .split("#")
            .filter((h) => !!h);
        let fromToken = false;
        if (hashData.length > 0) {
            const token = hashData.filter((h) => h.startsWith("t="));
            if (token.length === 1) {
                fromToken = true;
                userInfo = await API.GetUserInfoToken(token[0].replace("t=", ""));
            }
        } else {
            userInfo = await API.GetUserInfo();
        }

        if (!auth.isLoggedIn && userInfo) {
            setAuth({
                ...auth,
                isLoggedIn: true,
                userInfo: userInfo,
            });
            if (userInfo.transferData?.checkoutForms) {
                setCheckoutForms(userInfo.transferData.checkoutForms);
            }
        }
        if (fromToken) {
            router.replace(window.location.toString().split("#")[0]);
        }
    } catch (error) {
        setAuth({
            ...auth,
            isLoggedIn: false,
            userInfo: auth.userInfo,
        });
        if (errorCallback) {
            errorCallback();
        }
    }
}

export default function useUserInfo() {
    const [auth, setAuth] = useAtom(authAtom);
    const [, setCheckoutForms] = useAtom(checkoutFormsAtom);
    const router = useRouter();

    useEffect(() => {
        getUserInfo(auth, setAuth, setCheckoutForms, router);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
